import React, { useState, useEffect } from "react";
import { Typography,Box,Paper} from "@mui/material";
import { Introchecklist } from "../../data/Introchecklist";
import { useParams, useSearchParams } from "react-router-dom";
export default function Checklist() {
  const { id } = useParams();
  const [data, setData] = useState();
  useEffect(() => {
    let ans = Introchecklist.filter((obj) => {
      return obj.id == id;
    });
    console.log(ans);
    setData(ans);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);
  return (
    <>
      {data &&
        data.map((introchecklist) => (
          <Box sx={{backgroundImage:"linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)"}}>
            <Box sx={{ mx:27,py:4}}>
                <Paper sx={{p:3}}>
      <Typography sx={{fontWeight:"bold",fontSize:"2rem",textAlign:"center",mb:1}}>{introchecklist.hadding}</Typography>
     {introchecklist.intro}
     <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Software}</Typography>
     {introchecklist.Softwareinfo}
     <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Environment}</Typography>
     {introchecklist.Environmentinfo}
     <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Time}</Typography>
     {introchecklist.Timeinfo}
     <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Internet}</Typography>
     {introchecklist.Internetinfo}
     <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Power}</Typography>
     {introchecklist.Powerinfo}
     </Paper>
     </Box>
          </Box>
        ))}
    </>
  );
}
