import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { Modullist } from "../../data/Modullist";
import { useParams, useSearchParams } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { Typography,Box} from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function List() {
  const { id } = useParams();
  const [data, setData] = useState();
  useEffect(() => {
    let ans = Modullist.filter((obj) => {
      return obj.id == id;
    });
    console.log(ans);
    setData(ans);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);
  return (
    <>
      {data &&
        data.map((modulist) => (
          <Box sx={{mx:3}}>
          <TableContainer component={Paper} sx={{ maxHeight: 625, "&::-webkit-scrollbar": {
              width: "0.1em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,0)",
              outline: "1px solid #fff",
            }, }}>
            <Table  stickyHeader aria-label="sticky table" sx={{    }}>
              <TableHead >
                <TableRow >{modulist.tablehadding}</TableRow>
              </TableHead>
              <TableBody>{modulist.tablebody}</TableBody>
            </Table>
          </TableContainer>
          </Box>
        ))}
    </>
  );
}
