import logo from './logo.svg';
import './App.css';
import React from "react";
import {
  Routes,
  Route,
  Navigate,

} from "react-router-dom";

import Login from './Components/Login';
import Checklist from './Components/Doc/Checklist';
import List from './Components/Doc/List';
import PreOnboarding from './Components/PreOnboarding';
function PrivateOutlet() {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <PreOnboarding /> : <Navigate to="/" />;
}
function App() {
  return (
    <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/pre-onboarding' element={<PrivateOutlet/>} />
          <Route path='/Module/:id' element={<List />} />
          <Route path='/Checklist/:id' element={<Checklist />} />
    </Routes>
  );
}

export default App;
