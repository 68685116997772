import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Typography,Box} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
export const Modullist = [
{
    id:"PM",
    tablehadding:[
        <>
        <StyledTableCell sx={{fontSize:"1.2rem",fontWeight:"bold"}}>Terms</StyledTableCell>
        <StyledTableCell align="left" sx={{fontSize:"1.2rem",fontWeight:"bold"}}>Modules</StyledTableCell>
        <StyledTableCell align="left" sx={{fontSize:"1.2rem",fontWeight:"bold"}}>Session Topics</StyledTableCell>
    
        </>
    ],
    tablebody:[
        <>
        <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 1: Introduction to product management	
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Understanding Basics of Product Management</Typography>
                <Typography sx={{pt:6}}>Who is a Product Manager?</Typography>
                <Typography sx={{pt:6}}>Different Hats of a Product Manager</Typography>
                <Typography sx={{pt:6}}>Importance of Research in Product Management</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  What is product management?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Importance of product management
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Elements of product management
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Key terms & meanings for product management
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Mindset of PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />PM's standing in organization
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Types of PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Roles of PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Responsibilities of PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Collaborating as PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Influence without authority as PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Identifying target user groups
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Quantitative research technique
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Qualitative research technique
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> How to conduct customer reasearch
                  </Typography>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 2: Product Strategy & Roadmap
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>What is Product Planning Cycle?</Typography>
                <Typography sx={{pt:6}}>Establising Product Vision & Mission</Typography>
                <Typography sx={{pt:6}}>Formulating Product Strategy & Product Goals</Typography>
                <Typography sx={{pt:6}}>What is Product Backlog & Roadmap</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Undestanding product planning cycle
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Brief introduction of PPC elements
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Flow of PPC
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is product vision?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Setting product vision
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is product mission?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Setting product mission
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is product strategy?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Setting product strategy
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding product goals
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Setting goals that make difference
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is product roadmap?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Creating roadmap
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding product backlogs
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> How to create efficient product backlogs?
                  </Typography>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 3: Product Design Thinking
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>The Philosophy of Design Thinking</Typography>
                <Typography sx={{pt:6}}>Decoding Creativity & Innovation</Typography>
                <Typography sx={{pt:6}}>Design Thinking for PMs</Typography>
                <Typography sx={{pt:6}}>Building MVP - Artefacts, Persona Mapping, & PRD's</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is design thinking?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Importance of design thinking
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Developing design thinking mindset
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Steps in design thinking
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is creativity & innovation?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Importance of creativity for PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />How to innovate as PM?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Traps to avoid during innovation
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Design thinking in PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Implementing design thinking
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> How does design thinking benefit PM?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case Study about design thinking (Airbnb)
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> How to build an MVP?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Persona mapping, user journey, & user stories
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Product artefacts
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What are PRD's?
                  </Typography>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
        
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Mission: Design Discovery</Typography>
              
                </StyledTableCell>
              <StyledTableCell align="left">
                <Box display="flex">
            
                <Typography sx={{fontSize:"0.8rem",width:"297px"}}>
             Mission: Design Discovery requires one to apply design thinking
                  concepts to create human-centred and action-oriented solutions.
                  As a PM practitioner you will be competing with the best to solve
                  a complex challenge.
                  </Typography>
                  </Box>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 4: UI/UX for PMs
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>User Experience 101</Typography>
                <Typography sx={{pt:6}}>User Interface 101</Typography>
                <Typography sx={{pt:6}}>Importance of UX/UI for PMs</Typography>
                <Typography sx={{pt:6}}>Designing Mockups, Wireframes, & Prototypes</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is UX?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Building products with good UX
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Key points when building UX
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study about product UX
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is UI?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />How to build good UI?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Common elements of UI
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />How UI compliments UX?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Importance of UX for PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Importance of UI for PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Tools for UI/UX design
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Collaborating with UI/UX designer
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What are mockups?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What are wireframes?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What are prototypes?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Creating mockups, wireframes, & prototypes
                  </Typography>
                 </StyledTableCell>
                 
                 </StyledTableRow>

                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 5: Product Engineering
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Intoduction to Engineering for Product Managers1</Typography>
                <Typography sx={{pt:6}}>Understanding Microservices & Cloud Services</Typography>
                <Typography sx={{pt:6}}>Waterfall & Agile Methodology</Typography>
                <Typography sx={{pt:6}}>Understanding Scrum & Kanban</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is product engineering?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Midset of technical PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Should PM learn to code?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Technical concepts for PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What are microservices & cloud services?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is system design?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Understanding content delivery network
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Native apps & hybrid apps
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is waterfall methodolgy?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is agile methodology?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Traditional Vs Agile
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Agile frameworks
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is scrum?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is kanban?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Tools for scrum & kanban
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 6: Product Marketing & Launch
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Product Development & Planning User Tests</Typography>
                <Typography sx={{pt:6}}>Product Launch & A/B Testing</Typography>
                <Typography sx={{pt:6}}>Understanding Product Marketing & Consumer Behaviour</Typography>
                <Typography sx={{pt:6}}>Managing a Brand & Measuring Impact of Product Marketing</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Creating story & planning sprint
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Task breakdown & preparing documentation
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Planning,recruiting, & preparing for test
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Conducting tests & analyzing data
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Risk analysis
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />A/B testing
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Customer feedback mechanism
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is product marketing?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Goto market
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Growth hacking & sales strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding consumer behaviour
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Importance of brand
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Building brand
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding brand management
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Measuring Impact of product marketing activities
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
        
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Major Product</Typography>
              
                </StyledTableCell>
              <StyledTableCell align="left">
                <Box display="flex">
           
                <Typography sx={{fontSize:"0.8rem",width:"297px"}}>
                Wittr is a major micro blogging app popular across the globe. The management team has reached out to INSAID PM consultants to help them over come a major hurdle related to declining app usage by its customers. The company has collected data and figured that customers have been moving onto a major competitor app because it provides them a micro vlog feature. The company need help from you to strategize, plan and design the new feature in their app as well. Go ahead and help the company succeed using the new feature.
                  </Typography>
                  </Box>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 7: Analytics for Product Management
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Product Metrics</Typography>
                <Typography sx={{pt:6}}>How to Measure Product Success</Typography>
                <Typography sx={{pt:6}}>Analytical tools for PMs</Typography>
                <Typography sx={{pt:6}}>Basics of SQL for Product Managers</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Types of product & their metrics
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Google's HEART framework
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> AARRR
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Define MCS
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Fixing KPIs
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Measurement process
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Google analytics
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Mixpanel
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> HotJar
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  What is SQL?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Importance of SQL
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding database management
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Conducting data analysis using SQL
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 8: Growth & Scaling
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Understanding Product Growth</Typography>
                <Typography sx={{pt:6}}>Who is a Product-Growth Manager</Typography>
                <Typography sx={{pt:6}}>Product Growth Strategies</Typography>
                <Typography sx={{pt:6}}>Audience & Channel for Growth</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is product growth?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Product- led growth for organization
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Product - led growth frameworks
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Who is product-growth manager?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Essential skills for growth PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Duties of growth PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Future as growth PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What are product growth strategies?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Product growth strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Measuring performance of product growth strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Choosing audience for growth
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Different channels of growth
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Aligning audience, channels & growth strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case study
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 9: Product Monetization
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Understanding Product Monetization</Typography>
                <Typography sx={{pt:6}}>Decoding Buyer Psychology</Typography>
                <Typography sx={{pt:6}}>Product Pricing Models & Strategies</Typography>
                <Typography sx={{pt:6}}>Measuring Product Monetization Success</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is product monetization?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Why focus on monetization as PM?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Importance of product monetization
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Financial analysis of product & organization
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />What is buyer psychology?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Analyzing consumer purchasing behaviour
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />How to influence buyer psychology?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Understanding paths to purchase
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> What is pricing?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Product pricing ,models & strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Service pricing models & strategies
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Key points to consider when pricing
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Understanding KPIs : CAC, CPA
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Calcucating customer life time value (LTV)
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Metrics for measuring monetization success
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case study
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
         Term 10: Product Leadership
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>What is Product Leadership?</Typography>
                <Typography sx={{pt:6}}>Unlocking Interpersonal Skills & Mastering Technical Skills</Typography>
                <Typography sx={{pt:6}}>Developing Business Accumen & Acquiring Doimain Knowledge</Typography>
                <Typography sx={{pt:6}}>Becoming a Succesful Product Leader</Typography>
                </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Understanding product leadership
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Importance of product leadership
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />  Becoming product leader from PM
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Challenges for effective product leadership
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Interpersonal skill for product leader
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Importance of technical skills for product leader
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Implementing neccesary skills in daily tasks
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  />Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Importance of business accumen for product leaders
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Acquiring domain knowledge
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}> 
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Moving to multiple domains
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Case study
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Who is product leader?
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Understanding leadership styles
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Building teams
                  </Typography>
                  <Typography sx={{fontSize:"0.8rem"}}>
              <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#000", mx: 0.5 }}
                  /> Implementing user-centricity in team thinking
                  </Typography>
               
                 </StyledTableCell>
                 
                 </StyledTableRow>
                 <StyledTableRow>
         <StyledTableCell component="th" scope="row">
        
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{pt:2}}>Capstone Product</Typography>
              
                </StyledTableCell>
              <StyledTableCell align="left">
                <Box display="flex">
           
                <Typography sx={{fontSize:"0.8rem",width:"297px"}}>
                Musi.fy is struggling to come up with a potential solution to its dwindling revenues through its existing website for song lovers and urgently needs a dramatic shift from being Web first to Mobile first company. They have reached out to INSAID PM group and have requested for consultant PMs who can help solve their problem. In this capstone you will chalk out a plan and execute the product launch of Musi.fy Mobile app from scratch. Get a chance to be a PM from this awesome initiate of building a successful Music Streaming Mobile App.
                  </Typography>
                  </Box>
                 </StyledTableCell>
                 
                 </StyledTableRow>
                
        </>
    ]
},
{
  id:"DS",
  tablehadding:[
      <>
      <StyledTableCell sx={{fontSize:"1.2rem",fontWeight:"bold"}}>Terms</StyledTableCell>
      <StyledTableCell align="left" sx={{fontSize:"1.2rem",fontWeight:"bold", width:"370px"}}>Modules</StyledTableCell>
 
  
      </>
  ],
  tablebody:[
      <>
      <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 1: Data Analysis with Python
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Data Science Fundamentals</Typography>
              <Typography >Recap: Python for Data Science</Typography>
              <Typography >Recap: Statistics for Data Science</Typography>
              <Typography >Module 2: Data Operations with Numpy</Typography>
              </StyledTableCell>

               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 2: Data Visualization Techniques
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to Data Visualization</Typography>
              <Typography >Module 2: Data Visualization using Matplotlib</Typography>
              <Typography >Module 3: Hands-on Pandas for Rapid Visualization</Typography>
              <Typography >Module 4: Seaborn for Data Visualization</Typography>
              </StyledTableCell>
         
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 3: EDA & Data Storytelling
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to Exploratory Data Analysis</Typography>
              <Typography >Module 2: EDA Framework Deep Dive</Typography>
              <Typography >Module 3: Scientific Exploration of Industry Data - I & II</Typography>
              <Typography >Module 4: Student Presentations & Insight Delivery</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 4: Machine Learning Foundation
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Session 1: Introduction to Machine Learning (ML)</Typography>
              <Typography>Session 2: Linear Regression</Typography>
              <Typography>Session 3: Logistic Regression</Typography>
              <Typography>Session 4: Model Evaluation Techniques</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 5: Machine Learning Intermediate
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Session 1: Decision Trees</Typography>
              <Typography>Session 2: Random Forests</Typography>
              <Typography>Session 3: Dimensionality Reduction using PCA</Typography>
              <Typography>	Session 4: Naïve Bayes Classifier</Typography>
              </StyledTableCell>
          
               
               </StyledTableRow>

               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 6 [Mandatory]: Machine Learning Advanced
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Session 1: KNN (K- Nearest neighbors)</Typography>
              <Typography>Session 2: K-means Clustering</Typography>
              <Typography>Session 3: Ensemble Learning</Typography>
              <Typography>Session 4: Optimization</Typography>
              </StyledTableCell>
          
               
               </StyledTableRow>
               
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 6 [Elective - 1]: Data Visualization with Tableau
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Session 1: Introduction to Data Visualization with Tableau</Typography>
              <Typography>Session 2: Exploring Tableau Interface</Typography>
              <Typography>Session 3: Tableau Calculations</Typography>
              <Typography>Session 4: Tableau Dashboard</Typography>
              <Typography>Session 5: Mapping using Tableau</Typography>
              <Typography>Session 6: Tableau Analytics</Typography>
              <Typography>Session 7: In Class Project</Typography>
              <Typography>Session 8: Major Project</Typography>
              </StyledTableCell>
            
               </StyledTableRow>
              
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 6 [Elective - 2]: Data Analytics with R
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Session 1: Introduction to Programming in R</Typography>
              <Typography>Session 2. Data Manipulation with dplyr</Typography>
              <Typography>Session 3: Data Visualization using ggplot & esquisse</Typography>
              <Typography>Session 4: Exploratory Data Analysis - I</Typography>
              <Typography>Session 5: Exploratory Data Analysis - II</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 7: Basics of AI, TensorFlow, and Keras
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to Artificial Intelligence</Typography>
              <Typography>Module 2: Applications of Artificial Intelligence</Typography>
              <Typography>Module 3: Intro to TensorFlow and Keras</Typography>
              <Typography>Module 4: Math behind AI: Simplified</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
              
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 8: Deep Learning Foundation
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to Deep Learning</Typography>
              <Typography>Module 2: Components affecting Deep Learning Models - Activation Function and Learning Rate</Typography>
              <Typography>Module 3: Role of Optimizers in Deep Learning Models</Typography>
              <Typography>Module 4: Deep Learning Model Practical</Typography>
              <Typography>Module 5: Handwritten Digit (MNIST) Classifier</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 9: Computer Vision - I
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Intro to Convolutional Neural Networks</Typography>
              <Typography>Module 2: Decoding Image Components</Typography>
              <Typography>Module 3: Identifying MNIST using CNN</Typography>
              <Typography>Module 4: Preprocessing Image Data to apply CNN</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
    	Term 10: Natural Language Processing - I
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to NLP & Word Vectors</Typography>
              <Typography>Module 2: Decoding a Textual Data</Typography>
              <Typography>Module 3: NLP using Recurrent Neural Networks (RNN)</Typography>
              <Typography>Module 4: NLP using Memory Alterations</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       Term 11: Computer Vision - II
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Transfer Learning</Typography>
              <Typography>Module 2: Object Detection using CNN based Algorithms - I</Typography>
              <Typography>Module 3: Object Detection using CNN based Algorithms - II</Typography>
              <Typography>Module 4: Teaching Machines to Mimic Real-World (Generative Models)</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       	Term 12: Natural Language Processing - II
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Teach Machines to Generate New Textual Data (Language Modeling)</Typography>
              <Typography>Module 2: Keras Functional API and Intro to Sequence to Sequence Model</Typography>
              <Typography>Module 3: Language Translation - English to Spanish using Seq2Seq Models</Typography>
              <Typography>	Module 4: Building a Chatbot</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       		CV - III + NLP - III
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Techniques to Enhance Seq2Seq Models (Attention Mechanism)</Typography>
              <Typography>Module 2: NLP using CNN</Typography>
              <Typography>Module 3: Advanced NLP using BERT</Typography>
              <Typography>Module 4: Instance Segmentation in Images</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
               <StyledTableRow>
       <StyledTableCell component="th" scope="row">
       AutoVision & AutoNLP
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography sx={{pt:2}}>Module 1: Introduction to Automated Vision and NLP Solutions</Typography>
              <Typography>Module 2: Building Blocks of AutoVision</Typography>
              <Typography>Module 3: Building Blocks of AutoNLP</Typography>
              <Typography>Module 4: Putting AutoVision and AutoNLP to Use</Typography>
              </StyledTableCell>
           
               
               </StyledTableRow>
     
              
      </>
  ]
}
];