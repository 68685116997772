import { Typography,Box,Paper} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
export const Introchecklist = [
{
    id:"DS",
    hadding:"INSTRUCTORS - YOUR READINESS CHECKLIST",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Please make sure you have verified all the questions below and catered to them. This will avoid any hassles in your sessions on weekends. In case you have doubts on these further, drop us a note on<a href = "mailto:  academics@insaid.co"> academics@insaid.co</a> and we will take care of it.</Typography>],
    Software:"Software",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you accustomed to Zoom & Annotation in Zoom?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case you face difficulty in using annotations, have you tried using your mobile phone Zoom app to draw annotation while you teach using a laptop?
</Typography></Box>
        </>
    ],
    Environment:"Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a place, free of interruptions, to work at least on Saturday & Sunday?</Typography></Box>
        
        </>
    ],
    Time:"Time",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Would you be able to dedicate 2-3 hours per class every Saturday & Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you prepared for the weekend session before time?</Typography></Box>
        
        </>
    ],
    Internet:"Internet",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a reliable Internet connection for course access, communication with the students or RA, and material review?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>We recommend 10 mbps connection at least.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case you are travelling, do you have a 4G net connection with minimum fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep mobile 4G on when taking class as a backup to power/internet failure on the router?</Typography></Box>
        
        </>
    ],
    Power:"Power backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a power backup of 2 hours minimum (recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case of power failure, do you have a generator/ups/inverter to backup?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you fully charged your laptop before the session?</Typography></Box>
       
        
        </>
    ],
}, 
{
    id:"PM",
    hadding:"INSTRUCTORS - YOUR READINESS CHECKLIST",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Please make sure you have verified all the questions below and catered to them. This will avoid any hassles in your sessions on weekends. In case you have doubts on these further, drop us a note on<a href = "mailto:  pmacads@insaid.co">  pmacads@insaid.co</a> and we will take care of it.</Typography>],
    Software:"Software",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you accustomed to Zoom & Annotation in Zoom?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case you face difficulty in using annotations, have you tried using your mobile phone Zoom app to draw annotation while you teach using a laptop?
</Typography></Box>
        </>
    ],
    Environment:"Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a place, free of interruptions, to work at least on Saturday & Sunday?</Typography></Box>
        
        </>
    ],
    Time:"Time",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Would you be able to dedicate 2-3 hours per class every Saturday & Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you prepared for the weekend session before time?</Typography></Box>
        
        </>
    ],
    Internet:"Internet",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a reliable Internet connection for course access, communication with the students or RA, and material review?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>We recommend 10 mbps connection at least.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case you are travelling, do you have a 4G net connection with minimum fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep mobile 4G on when taking class as a backup to power/internet failure on the router?</Typography></Box>
        
        </>
    ],
    Power:"Power backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a power backup of 2 hours minimum (recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In case of power failure, do you have a generator/ups/inverter to backup?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you fully charged your laptop before the session?</Typography></Box>
       
        
        </>
    ],
}, 
];